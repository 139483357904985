<template>
    <v-main id="default-layout" class="fill-height flex-shrink-1">
        <router-view
            :key="$route.path" 
        />
    </v-main>
</template>
<script>
export default {
    data() {
        return {
            ready: true,
            drawer: true
        }
    }
};
</script>